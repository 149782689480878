import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import axios from '../../api/axios'
import toast from 'react-hot-toast'

export default function PriceList() {
   const account = useSelector((state) => state.account)
   const [allData, setAllData] = useState([])


   const getAllPriceList = async () => {
      try {
         await axios
            .post(`getAllPrice`)
            .then((result) => {
               if (result) {
                  setAllData(result.data)
               } else {
                  toast.error('Internal server error')
               }
            })
      } catch (error) {
         if (process.env.REACT_APP_MODE) console.log(error)
      }
   }

   useEffect(() => {
      getAllPriceList()
   }, [account])

   return (
      <Box
         sx={{
            flexGrow: 1,
            p: 3,
            bgcolor: 'rgb(229, 229, 229)',
            overflowY: 'overlay',
         }}
      >
         <Box sx={{ mt: '80px' }}>
            <h3 style={{ color: '#FA870B', margin: '0px' }}>
               Price List
            </h3>
         </Box>
         <Box
            sx={{
            mt: '10px',
            borderTop: '5px solid #FA870B',
            bgcolor: 'white',
            borderBottomRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            p: '10px',
            }}
         >
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                {/*
                <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ color: '#FA870B', fontSize: '20px' }}>Price List</Box>
                </Grid>
                */}
                <Grid item xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                            {/* <TableCell
                                style={{ fontWeight: 'bold', fontSize: '16px' }}
                            >
                                ID
                            </TableCell> */}
                            <TableCell
                                style={{ fontWeight: 'bold', fontSize: '16px' }}
                            >
                                Service Type
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{ fontWeight: 'bold', fontSize: '16px' }}
                                >
                                Service
                            </TableCell>
                            <TableCell
                                align="right"
                                style={{ fontWeight: 'bold', fontSize: '16px' }}
                            >
                                Credit Cost
                            </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allData.map((row, ind) => (
                            <TableRow
                                key={row._id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                {/* <TableCell component="th" scope="row">
                                    {ind + 1}
                                </TableCell> */}
                                <TableCell component="th" scope="row">{row.serviceType}</TableCell>
                                <TableCell align="right">{row.service}</TableCell>
                                <TableCell align="right">{row.credit}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
              </Grid>
            </Grid>
         </Box>
      </Box>
   )
}
