import React from 'react'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { useStripe } from '@stripe/react-stripe-js'
import toast from 'react-hot-toast'
import axios from '../../../api/axios'

function ElementTag() {
   const stripe = useStripe()
   const [message, setMessage] = React.useState(null)
   const [statusIcon, setStatusIcon] = React.useState(null)
   const [account, setAccount] = React.useState(null)

   React.useEffect(() => {
      let user = localStorage.getItem('user')
      if (user) {
         let parse = JSON.parse(user)
         setAccount(parse)
      }
   }, [])

   const handleRedirection = () => {
       toast.success('Redirecting Back ...')
      setTimeout(() => {
         window.location.replace(`${window.location.origin}/dashboard`)
      }, 500)
   }

   const getCustomDate = () => {
      const d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      let hour = d.getHours()
      let minute = d.getMinutes()
      if (month < 10) month = '0' + month
      if (day < 10) day = '0' + day
      if (hour < 10) hour = '0' + hour
      if (minute < 10) minute = '0' + minute

      const result = `${year}-${month}-${day} ${hour}:${minute}`
      return result
   }

   const subscriptionDone = async () => {
      let s_data = localStorage.getItem('s_Data')
      if (s_data) {
         let stripeData = JSON.parse(s_data)
         const data = {
            account: account,
            other: {
               userId: account._id,
               name: account.name,
               email: account.email,
               vatNumber: account.vatNumber,
               receipt: '',
               credits: stripeData.credits,
               netAmount: stripeData.netAmount,
               date: getCustomDate(),
               method: 'card',
               fee: stripeData.fee,
               vatCharge: stripeData.vatCharge,
               tax: stripeData.tax,
            },
         }

         try {
            await axios.post(`buyCredit`, { data }).then((result) => {
               if (result.status === 200) {
                  toast.success(
                     `You have bought ${stripeData.credits} credits for ${stripeData.netAmount} kr`
                  )
                  localStorage.removeItem('s_Data')
                  handleRedirection()
               } else {
                  setStatusIcon(
                     <ErrorOutlineIcon
                        sx={{ fontSize: '60px' }}
                        color={'error'}
                     />
                  )

                  toast.error('Something went wrong')
                  localStorage.removeItem('s_Data')
                  // handleRedirection()
               }
            })
         } catch (error) {
            if (process.env.REACT_APP_MODE) console.log(error)
         }
      }
   }

   React.useEffect(() => {
      if (!stripe) {
         return
      }

      const clientSecret = new URLSearchParams(window.location.search).get(
         'payment_intent_client_secret'
      )

      if (!clientSecret) {
         return
      }

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
         switch (paymentIntent.status) {
            case 'succeeded':
               setMessage('Payment succeeded!')
               subscriptionDone()
               setStatusIcon(
                  <CheckCircleOutlineIcon
                     sx={{ fontSize: '60px' }}
                     color={'success'}
                  />
               )
               break
            case 'processing':
               setMessage('Your payment is processing.')
               setStatusIcon(
                  <HourglassEmptyIcon
                     sx={{ fontSize: '60px' }}
                     color={'info'}
                  />
               )
               break
            case 'requires_payment_method':
               setMessage('Your payment was not successful, please try again.')
               setStatusIcon(
                  <ErrorOutlineIcon sx={{ fontSize: '60px' }} color={'error'} />
               )
               break
            default:
               setMessage('Something went wrong.')
               setStatusIcon(
                  <ErrorOutlineIcon sx={{ fontSize: '60px' }} color={'error'} />
               )
               break
         }
      })
   }, [stripe])

   return (
      <Box
         sx={{
            width: '100%',
            display: 'flex',
            height: '50vh',
            justifyContent: 'center',
            paddingTop: '5%',
            margin: 'auto',
         }}
      >
         <Paper
            elevation={5}
            sx={{
               width: '500px',
               height: '200px',
               borderRadius: '10px',
               margin: '0 auto',
            }}
         >
            <Box
               display={'flex'}
               flexDirection={'column'}
               alignItems={'center'}
               justifyContent={'center'}
               height={'inherit'}
               mx={2}
            >
               {!message ? (
                  <CircularProgress />
               ) : (
                  <>
                     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        {statusIcon}
                     </Box>
                     <Box>
                        <Typography sx={{ fontSize: '18px' }}>
                           {message}
                        </Typography>
                     </Box>
                  </>
               )}
            </Box>
         </Paper>
      </Box>
   )
}

export default ElementTag
